#main{
  max-width: 800px;
  margin: 100px auto;
  display: flex;
}
form, .json-data{
  background: #dedede;
  width: 50%;
  padding: 18px;
}
.form-field{
  margin: 10px 0;
}
.submit-container{
  text-align: center;
  margin: 10px auto;
}
.json-data{
  background: #acacac;
  padding: 10px;
}
.form-field{
  display: flex;
  white-space: nowrap;
}
.form-field input{
  width: 100%;
}
.submitted{
  background: #66aa66;
  padding: 12px;
}